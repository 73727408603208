import { coinModel } from '@models/coin.model';
import { useLoading, useModel } from 'foca';
import { useEffect, useMemo } from 'react';
// import { useRefreshFlag } from './use-refresh';
import { throttle } from 'lodash-es';
import { localCoinModel } from '@models/local-coin.model';

const fetchCoins = throttle(coinModel.getCoins, 10, { leading: true, trailing: false });

export const useCoins = () => {
  const coins = useModel(coinModel);
  const loading = useLoading(coinModel.getCoins);
  // const { refresh } = useRefreshFlag();

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    const loop = () => {
      fetchCoins();
      timer = setTimeout(() => {
        loop();
      }, 1000 * 60 * 5);
    };
    loop();
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return { coins, loading };
};

export const useCoin = (coinType: string | undefined) => {
  const localCoins = useModel(localCoinModel);
  const { coins } = useCoins();
  if (!coinType) return;
  return [...localCoins, ...coins].find((item) => item.type === coinType);
};

export const useStableCoinIds = () => {
  const { coins } = useCoins();
  return useMemo(() => {
    return coins.filter((coin) => coin.is_stable).map((coin) => coin.type);
  }, [coins]);
};
