import http from 'src/services/http';
import defaultCoinIcon from '@assets/images/icons/default-coin.svg';
import { defineModel } from 'foca';

export interface TurbosCoinItem {
  id: number;
  name: string;
  type: string;
  symbol: string;
  decimals: number;
  logo_url: string;
  coingecko_id: string;
  in_quote_list: boolean;
  is_stable: boolean;
  in_pool: boolean;
  category_id: number;
  faucet_amount: string;
  created_at: Date;
  updated_at: Date;
  is_popular: boolean;
  is_hot: boolean;
  cross_chain: string | null;
  category: {
    badge_url: string;
    id: number;
    name: string;
  };
  pyth_id: string | null;
  flag: 0 | 1 | 2;
}

const initialState: TurbosCoinItem[] = [];

export const coinModel = defineModel('coin', {
  initialState,
  methods: {
    async getCoins() {
      const result = await http.get<TurbosCoinItem[]>(`/coins`, {
        cache: {
          maxAge: 300_000,
        },
      });
      this.setState(
        result.map((coin) => ({
          ...coin,
          logo_url: coin.logo_url || defaultCoinIcon,
        })),
      );
      return result;
    },
  },
  events: {
    onInit() {
      // coins are used everywhere
      this.getCoins();
    },
  },
});
