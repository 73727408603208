import { turbosSdk } from './turbos-sdk';
import { DeepBookClient, DeepBookConfig } from '@mysten/deepbook-v3';
import { config } from '@config';
import { coinWithBalance, Transaction, type TransactionArgument } from '@mysten/sui/transactions';
import { Decimal } from 'turbos-clmm-sdk';
import { SUI_CLOCK_OBJECT_ID } from '@mysten/sui/utils';
import {
  type CancelOrderParams,
  type CreateDepositThenPlaceLimitOrderParams,
  type CreateDepositThenPlaceMarketOrderParams,
  type DepositParams,
  OrderType,
  SelfMatchingOptions,
  type ShareParams,
  type WithdrawParams,
  type WithdrawAllParams,
  type DepositThenPlaceLimitOrderParams,
  type DepositThenPlaceMarketOrderParams,
  type BalanceManagerEvents,
  type PlaceLimitOrderParams,
  type WithdrawSettledParams,
  type LimitAccount,
} from './deepbook-type';
import { bcs } from '@mysten/sui/bcs';
import { multiGetObjects } from '@utils/sui-kit';
import { deepbookV3Config } from 'src/configs/deepbook-v3';

const deepBookV3 = deepbookV3Config[turbosSdk.network];
const TURBOS_DEEPBOOK_V3_PACKAGE_ID = deepBookV3.TURBOS_DEEPBOOK_V3_PACKAGE_ID;
const TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG = deepBookV3.TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG;
const TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX =
  deepBookV3.TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX;

const MAX_TIMESTAMP = 1844674407370955161n;
const FLOAT_SCALAR = 1000000000;

const GAS_BUDGET = 0.5 * 500000000;
const DEEP_SCALAR = 1000000;

class DeepbookMarketMaker {
  address!: string;
  client!: DeepBookClient;
  deepBookConfig!: DeepBookConfig;

  turbosBalanceManger!: string;

  private _pools:
    | { poolKey: string; address: string; baseCoin: string; quoteCoin: string }[]
    | null = null;
  private _coins: { type: string; decimals: number; symbol: string }[] | null = null;

  constructor(address: string) {
    this.updateAddress(address);
  }

  protected get deepbook() {
    return this.client.deepBook;
  }

  get sui() {
    return this.client.client;
  }

  updateAddress(address: string) {
    this.address = address;
    this.turbosBalanceManger = '';

    this.client = new DeepBookClient({
      address,
      env: config.deepbook_env,
      // @ts-ignore
      client: turbosSdk.provider,
    });

    this.deepBookConfig = new DeepBookConfig({
      address,
      env: config.deepbook_env,
    });
  }

  swapExactBaseForQuote(data: {
    poolKey: string;
    amountIn: number | string;
    amountOut: number | string;
    deepbookFee: number | string;
    slippage: number | string;
  }) {
    const { poolKey, amountIn, amountOut, deepbookFee: _, slippage } = data;
    const minQuote = new Decimal(amountOut)
      .minus(new Decimal(amountOut).mul(slippage).div(100))
      .toFixed(0);
    const tx = new Transaction();
    const pool = this.deepBookConfig.getPool(poolKey)!;
    const baseCoin = this.deepBookConfig.getCoin(pool.baseCoin)!;
    const quoteCoin = this.deepBookConfig.getCoin(pool.quoteCoin)!;

    tx.setGasBudgetIfNotSet(GAS_BUDGET);
    tx.setSenderIfNotSet(this.address);

    const [baseOut, quoteOut] = tx.moveCall({
      target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::swap_exact_base_for_quote_sponsored`,
      typeArguments: [baseCoin.type, quoteCoin.type],
      arguments: [
        tx.object(TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG),
        tx.object(pool.address),
        coinWithBalance({ type: baseCoin.type, balance: Number(amountIn) }),
        tx.pure.u64(minQuote),
        tx.object(SUI_CLOCK_OBJECT_ID),
      ],
    });
    tx.transferObjects([baseOut!, quoteOut!], tx.pure.address(this.address));
    return tx;
  }

  swapExactQuoteForBase(data: {
    poolKey: string;
    amountIn: number | string;
    amountOut: number | string;
    deepbookFee: number | string;
    slippage: number | string;
  }) {
    const { poolKey, amountIn, amountOut, deepbookFee: _, slippage } = data;
    const minBase = new Decimal(amountOut)
      .minus(new Decimal(amountOut).mul(slippage).div(100))
      .toFixed(0);
    const tx = new Transaction();
    const pool = this.deepBookConfig.getPool(poolKey)!;
    const baseCoin = this.deepBookConfig.getCoin(pool.baseCoin)!;
    const quoteCoin = this.deepBookConfig.getCoin(pool.quoteCoin)!;

    tx.setGasBudgetIfNotSet(GAS_BUDGET);
    tx.setSenderIfNotSet(this.address);

    const [baseOut, quoteOut] = tx.moveCall({
      target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::swap_exact_quote_for_base_sponsored`,
      typeArguments: [baseCoin.type, quoteCoin.type],
      arguments: [
        tx.object(TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG),
        tx.object(pool.address),
        coinWithBalance({ type: quoteCoin.type, balance: Number(amountIn) }),
        tx.pure.u64(minBase),
        tx.object(SUI_CLOCK_OBJECT_ID),
      ],
    });
    tx.transferObjects([baseOut!, quoteOut!], tx.pure.address(this.address));
    return tx;
  }

  async devInspectSwapExactBaseForQuote(poolKey: string, amount: number | string) {
    const pool = this.findPoolByKey(poolKey)!;
    const result = await this.client.getQuoteQuantityOut(
      poolKey,
      this.scaleIn(amount, pool.baseCoin),
    );

    return {
      amountOut: result.quoteOut,
      amountIn: result.baseQuantity,
      deepbookFee: result.deepRequired,
    };
  }

  async devInspectSwapExactQuoteForBase(poolKey: string, amount: number | string) {
    const pool = this.findPoolByKey(poolKey)!;
    const result = await this.client.getBaseQuantityOut(
      poolKey,
      this.scaleIn(amount, pool.quoteCoin),
    );

    return {
      amountIn: result.quoteQuantity,
      amountOut: result.baseOut,
      deepbookFee: result.deepRequired,
    };
  }

  // deepbook sdk

  async getOrder(poolKey: string, orderId: string) {
    return await this.client.getOrder(poolKey, orderId);
  }

  async getQuoteQuantityOut(poolKey: string, baseQuantity: number) {
    return await this.client.getQuoteQuantityOut(poolKey, baseQuantity);
  }

  async getBaseQuantityOut(poolKey: string, quoteQuantity: number) {
    return await this.client.getQuoteQuantityOut(poolKey, quoteQuantity);
  }

  async getQuantityOut(poolKey: string, baseQuantity: number, quoteQuantity: number) {
    return await this.client.getQuantityOut(poolKey, baseQuantity, quoteQuantity);
  }

  async midPrice(poolKey: string) {
    return this.client.midPrice(poolKey);
  }

  async getLevel2Range(poolKey: string, lowPrice: number, highPrice: number, isBid: boolean) {
    return await this.client.getLevel2Range(poolKey, lowPrice, highPrice, isBid);
  }

  async poolBookParams(poolKey: string) {
    return await this.client.poolBookParams(poolKey);
  }

  async getLevel2TicksFromMid(poolKey: string, ticks: number) {
    return await this.client.getLevel2TicksFromMid(poolKey, ticks);
  }

  /** turbos contract **/

  async getBalanceManagersByOwner(address: string) {
    if (this.turbosBalanceManger && this.address === address) {
      return this.turbosBalanceManger;
    }

    const tx = new Transaction();
    tx.moveCall({
      target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::get_balance_managers_by_owner`,
      arguments: [tx.object(TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX), tx.pure.address(address)],
    });

    try {
      const res = await this.sui.devInspectTransactionBlock({
        // @ts-ignore
        transactionBlock: tx,
        sender: address,
      });
      const events = res.events as BalanceManagerEvents;
      // @ts-ignore
      const objects = await multiGetObjects(this.sui, events[0]!.parsedJson.list, {
        showContent: true,
      });
      const final = objects.filter((item) => !item.error);
      this.turbosBalanceManger = final[0]?.data?.objectId || '';
      return this.turbosBalanceManger;
    } catch (err) {
      return '';
    }
  }

  cancelOrder(params: CancelOrderParams) {
    return (tx: Transaction) => {
      const { poolKey, orderId, turbosBalanceManager } = params;
      const pool = this.deepBookConfig.getPool(poolKey);
      const baseCoin = this.deepBookConfig.getCoin(pool.baseCoin);
      const quoteCoin = this.deepBookConfig.getCoin(pool.quoteCoin);

      tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::cancel_order`,
        arguments: [
          tx.object(TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG),
          tx.object(turbosBalanceManager),
          tx.object(pool.address),
          tx.pure.u128(orderId),
          tx.object(SUI_CLOCK_OBJECT_ID),
        ],
        typeArguments: [baseCoin.type, quoteCoin.type],
      });
    };
  }

  createTurbosBalanceManager() {
    return (tx: Transaction) => {
      return tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::create_turbos_balance_manager`,
        arguments: [tx.object(TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX)],
      });
    };
  }

  share(params: ShareParams) {
    return (tx: Transaction) => {
      const { turbosBalanceManager } = params;
      tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::turbos_balance_manager::share`,
        arguments: [
          typeof turbosBalanceManager == 'string'
            ? tx.object(turbosBalanceManager)
            : turbosBalanceManager,
        ],
      });
    };
  }

  deposit(params: DepositParams) {
    return (tx: Transaction) => {
      const { turbosBalanceManager, coinKey, amountToDeposit } = params;
      const coin = this.deepBookConfig.getCoin(coinKey);
      const inputQuantity = Math.round(amountToDeposit * coin.scalar);
      const balance = coinWithBalance({ type: coin.type, balance: inputQuantity });

      tx.setSenderIfNotSet(this.address);
      tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::turbos_balance_manager::deposit`,
        arguments: [
          typeof turbosBalanceManager == 'string'
            ? tx.object(turbosBalanceManager)
            : turbosBalanceManager,
          balance,
        ],
        typeArguments: [coin.type],
      });
    };
  }

  withdraw(params: WithdrawParams) {
    return (tx: Transaction) => {
      const { turbosBalanceManager, coinKey, amountToWithdraw } = params;
      const coin = this.deepBookConfig.getCoin(coinKey);
      const withdrawAmount = Math.round(amountToWithdraw * coin.scalar);
      tx.setSenderIfNotSet(this.address);
      const coinObject = tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::turbos_balance_manager::withdraw`,
        arguments: [tx.object(turbosBalanceManager), tx.pure.u64(withdrawAmount)],
        typeArguments: [coin.type],
      });
      return coinObject;
    };
  }

  withdrawAll(params: WithdrawAllParams) {
    return (tx: Transaction) => {
      const { turbosBalanceManager, coinKey, recipient } = params;
      const coin = this.deepBookConfig.getCoin(coinKey);
      tx.setSenderIfNotSet(this.address);
      const coinObject = tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::turbos_balance_manager::withdraw_all`,
        arguments: [tx.object(turbosBalanceManager)],
        typeArguments: [coin.type],
      });
      tx.transferObjects([coinObject], recipient);
    };
  }

  withdrawSettled(params: WithdrawSettledParams) {
    return (tx: Transaction) => {
      const { turbosBalanceManager, poolKey } = params;
      const pool = this.deepBookConfig.getPool(poolKey);
      const baseCoin = this.deepBookConfig.getCoin(pool.baseCoin);
      const quoteCoin = this.deepBookConfig.getCoin(pool.quoteCoin);

      tx.setSenderIfNotSet(this.address);
      const coinObject = tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::turbos_balance_manager::withdraw_settled_amounts`,
        arguments: [tx.object(pool.address), tx.object(turbosBalanceManager)],
        typeArguments: [baseCoin.type, quoteCoin.type],
      });
      return coinObject;
    };
  }

  createDepositThenPlaceLimitOrder(params: CreateDepositThenPlaceLimitOrderParams) {
    return (tx: Transaction) => {
      const {
        poolKey,
        baseCoinKey,
        quoteCoinKey,
        isBid,
        price,
        quantity,
        orderType = OrderType.NO_RESTRICTION,
        selfMatchingOption = SelfMatchingOptions.SELF_MATCHING_ALLOWED,
        feeIsDeep = true,
        expireTimestamp = MAX_TIMESTAMP,
      } = params;
      const pool = this.deepBookConfig.getPool(poolKey);
      const baseCoin = this.deepBookConfig.getCoin(baseCoinKey);
      const quoteCoin = this.deepBookConfig.getCoin(quoteCoinKey);

      const inputPrice = this.calculatePrice(price, baseCoin.scalar, quoteCoin.scalar);
      const inputQuantity = Math.round(quantity * baseCoin.scalar);

      const baseCoinBalance = isBid
        ? this.zero(baseCoin.type)(tx)
        : coinWithBalance({ type: baseCoin.type, balance: inputQuantity });
      const quoteCoinBalance = isBid
        ? coinWithBalance({
            type: quoteCoin.type,
            balance: Math.round(price * quantity * quoteCoin.scalar),
          })
        : this.zero(quoteCoin.type)(tx);

      tx.setSenderIfNotSet(this.address);
      tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::create_deposit_then_place_limit_order`,
        arguments: [
          tx.object(TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG),
          tx.object(TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX),
          tx.object(pool.address),
          tx.pure.u64(Date.now()), // order id
          baseCoinBalance,
          quoteCoinBalance,
          tx.pure.u8(orderType),
          tx.pure.u8(selfMatchingOption),
          tx.pure.u64(inputPrice),
          tx.pure.u64(inputQuantity),
          tx.pure.bool(isBid),
          tx.pure.bool(feeIsDeep),
          tx.pure.u64(expireTimestamp),
          tx.object(SUI_CLOCK_OBJECT_ID),
        ],
        typeArguments: [baseCoin.type, quoteCoin.type],
      });
    };
  }

  createDepositThenPlaceMarketOrder(params: CreateDepositThenPlaceMarketOrderParams) {
    return (tx: Transaction) => {
      const {
        poolKey,
        baseCoinKey,
        quoteCoinKey,
        isBid,
        quantity,
        balance,
        selfMatchingOption = SelfMatchingOptions.SELF_MATCHING_ALLOWED,
        feeIsDeep = true,
        coinObject,
      } = params;
      const pool = this.deepBookConfig.getPool(poolKey);
      const baseCoin = this.deepBookConfig.getCoin(baseCoinKey);
      const quoteCoin = this.deepBookConfig.getCoin(quoteCoinKey);
      const inputQuantity = Math.round(quantity * baseCoin.scalar);

      const baseCoinBalance = isBid
        ? this.zero(baseCoin.type)(tx)
        : coinWithBalance({ type: baseCoin.type, balance: Math.round(balance * baseCoin.scalar) })(
            tx,
          );

      const quoteCoinBalance = isBid
        ? coinWithBalance({
            type: quoteCoin.type,
            balance: Math.round(balance * quoteCoin.scalar),
          })(tx)
        : this.zero(quoteCoin.type)(tx);

      if (coinObject) {
        tx.mergeCoins(isBid ? quoteCoinBalance : baseCoinBalance, [coinObject]);
      }

      tx.setSenderIfNotSet(this.address);
      tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::create_deposit_then_place_market_order`,
        arguments: [
          tx.object(TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG),
          tx.object(TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX),
          tx.object(pool.address),
          baseCoinBalance,
          quoteCoinBalance,
          tx.pure.u8(selfMatchingOption),
          tx.pure.u64(inputQuantity),
          tx.pure.bool(isBid),
          tx.pure.bool(feeIsDeep),
          tx.object(SUI_CLOCK_OBJECT_ID),
        ],
        typeArguments: [baseCoin.type, quoteCoin.type],
      });
    };
  }

  createDepositThenPlaceMarketOrderReturnCoins(params: CreateDepositThenPlaceMarketOrderParams) {
    return (tx: Transaction) => {
      const {
        poolKey,
        baseCoinKey,
        quoteCoinKey,
        isBid,
        quantity,
        balance,
        selfMatchingOption = SelfMatchingOptions.SELF_MATCHING_ALLOWED,
        feeIsDeep = true,
        coinObject,
      } = params;
      const pool = this.deepBookConfig.getPool(poolKey);
      const baseCoin = this.deepBookConfig.getCoin(baseCoinKey);
      const quoteCoin = this.deepBookConfig.getCoin(quoteCoinKey);
      const inputQuantity = Math.round(quantity * baseCoin.scalar);

      const baseCoinBalance = isBid
        ? this.zero(baseCoin.type)(tx)
        : coinWithBalance({ type: baseCoin.type, balance: Math.round(balance * baseCoin.scalar) })(
            tx,
          );

      const quoteCoinBalance = isBid
        ? coinWithBalance({
            type: quoteCoin.type,
            balance: Math.round(balance * quoteCoin.scalar),
          })(tx)
        : this.zero(quoteCoin.type)(tx);

      if (coinObject) {
        tx.mergeCoins(isBid ? quoteCoinBalance : baseCoinBalance, [coinObject]);
      }

      tx.setSenderIfNotSet(this.address);
      const [coinA, coinB] = tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::create_deposit_then_place_market_order_with_return`,
        arguments: [
          tx.object(TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG),
          tx.object(TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX),
          tx.object(pool.address),
          baseCoinBalance,
          quoteCoinBalance,
          tx.pure.u8(selfMatchingOption),
          tx.pure.u64(inputQuantity),
          tx.pure.bool(isBid),
          tx.pure.bool(feeIsDeep),
          tx.object(SUI_CLOCK_OBJECT_ID),
        ],
        typeArguments: [baseCoin.type, quoteCoin.type],
      });

      return [coinA, coinB];
    };
  }

  checkThreshold(coinKey: string, coin: TransactionArgument, amount: number) {
    return (tx: Transaction) => {
      const quoteCoin = this.deepBookConfig.getCoin(coinKey);
      tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::check_threshold`,
        arguments: [tx.object(coin), tx.pure.u64(Math.round(amount * quoteCoin.scalar))],
        typeArguments: [quoteCoin.type],
      });
    };
  }

  depositThenPlaceLimitOrderByOwner(params: DepositThenPlaceLimitOrderParams) {
    return (tx: Transaction) => {
      const {
        turbosBalanceManager,
        poolKey,
        baseCoinKey,
        quoteCoinKey,
        isBid,
        price,
        quantity,
        feeBalance,
        orderType = OrderType.NO_RESTRICTION,
        selfMatchingOption = SelfMatchingOptions.SELF_MATCHING_ALLOWED,
        feeIsDeep = true,
        expireTimestamp = MAX_TIMESTAMP,
      } = params;

      const pool = this.deepBookConfig.getPool(poolKey);
      const baseCoin = this.deepBookConfig.getCoin(baseCoinKey);
      const quoteCoin = this.deepBookConfig.getCoin(quoteCoinKey);

      const inputPrice = this.calculatePrice(price, baseCoin.scalar, quoteCoin.scalar);
      const inputQuantity = Math.round(quantity * baseCoin.scalar);

      const baseCoinBalance = isBid
        ? this.zero(baseCoin.type)(tx)
        : coinWithBalance({
            type: baseCoin.type,
            balance: Math.round((quantity - feeBalance) * baseCoin.scalar),
          });

      const quoteCoinBalance = isBid
        ? coinWithBalance({
            type: quoteCoin.type,
            balance: Math.round((price * quantity - feeBalance) * quoteCoin.scalar),
          })
        : this.zero(quoteCoin.type)(tx);

      tx.setSenderIfNotSet(this.address);
      tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::deposit_then_place_limit_order_by_owner`,
        arguments: [
          tx.object(TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG),
          tx.object(TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX),
          tx.object(pool.address),
          tx.object(turbosBalanceManager),
          tx.pure.u64(Date.now()),
          baseCoinBalance,
          quoteCoinBalance,
          tx.pure.u8(orderType),
          tx.pure.u8(selfMatchingOption),
          tx.pure.u64(inputPrice),
          tx.pure.u64(inputQuantity),
          tx.pure.bool(isBid),
          tx.pure.bool(feeIsDeep),
          tx.pure.u64(expireTimestamp),
          tx.object(SUI_CLOCK_OBJECT_ID),
        ],
        typeArguments: [baseCoin.type, quoteCoin.type],
      });
    };
  }

  depositThenPlaceMarketOrderByOwner(params: DepositThenPlaceMarketOrderParams) {
    return (tx: Transaction) => {
      const {
        turbosBalanceManager,
        poolKey,
        baseCoinKey,
        quoteCoinKey,
        isBid,
        quantity,
        selfMatchingOption = SelfMatchingOptions.SELF_MATCHING_ALLOWED,
        feeIsDeep = true,
      } = params;
      const pool = this.deepBookConfig.getPool(poolKey);
      const baseCoin = this.deepBookConfig.getCoin(baseCoinKey);
      const quoteCoin = this.deepBookConfig.getCoin(quoteCoinKey);
      const inputQuantity = isBid
        ? Math.round(quantity * quoteCoin.scalar)
        : Math.round(quantity * baseCoin.scalar);

      const baseCoinBalance = isBid
        ? this.zero(baseCoin.type)(tx)
        : coinWithBalance({ type: baseCoin.type, balance: inputQuantity });
      const quoteCoinBalance = isBid
        ? coinWithBalance({ type: quoteCoin.type, balance: inputQuantity })
        : this.zero(quoteCoin.type)(tx);

      tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::create_deposit_then_place_market_order`,
        arguments: [
          tx.object(TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG),
          tx.object(TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX),
          tx.object(pool.address),
          tx.object(turbosBalanceManager),
          baseCoinBalance,
          quoteCoinBalance,
          tx.pure.u8(selfMatchingOption),
          tx.pure.u64(inputQuantity),
          tx.pure.bool(isBid),
          tx.pure.bool(feeIsDeep),
          tx.object(SUI_CLOCK_OBJECT_ID),
        ],
        typeArguments: [baseCoin.type, quoteCoin.type],
      });
    };
  }

  placeLimitOrder(params: PlaceLimitOrderParams) {
    return (tx: Transaction) => {
      const {
        turbosBalanceManager,
        poolKey,
        baseCoinKey,
        quoteCoinKey,
        isBid,
        price,
        quantity,
        orderType = OrderType.NO_RESTRICTION,
        selfMatchingOption = SelfMatchingOptions.SELF_MATCHING_ALLOWED,
        feeIsDeep = true,
        expireTimestamp = MAX_TIMESTAMP,
      } = params;
      const pool = this.deepBookConfig.getPool(poolKey);
      const baseCoin = this.deepBookConfig.getCoin(baseCoinKey);
      const quoteCoin = this.deepBookConfig.getCoin(quoteCoinKey);

      const inputPrice = this.calculatePrice(price, baseCoin.scalar, quoteCoin.scalar);
      const inputQuantity = Math.round(quantity * baseCoin.scalar);

      tx.setSenderIfNotSet(this.address);
      tx.moveCall({
        target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::deepbookv3_wrapped::place_limit_order`,
        arguments: [
          tx.object(TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG),
          tx.object(TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX),
          tx.object(pool.address),
          tx.object(turbosBalanceManager),
          tx.pure.u64(Date.now()),
          tx.pure.u8(orderType),
          tx.pure.u8(selfMatchingOption),
          tx.pure.u64(inputPrice),
          tx.pure.u64(inputQuantity),
          tx.pure.bool(isBid),
          tx.pure.bool(feeIsDeep),
          tx.pure.u64(expireTimestamp),
          tx.object(SUI_CLOCK_OBJECT_ID),
        ],
        typeArguments: [baseCoin.type, quoteCoin.type],
      });
    };
  }

  async checkManagerBalance(turbosBalanceManager: string, coinKey: string) {
    const tx = new Transaction();
    const coin = this.deepBookConfig.getCoin(coinKey);
    tx.moveCall({
      target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::turbos_balance_manager::balance`,
      arguments: [tx.object(turbosBalanceManager)],
      typeArguments: [coin.type],
    });

    try {
      const res = await this.sui.devInspectTransactionBlock({
        // @ts-ignore
        transactionBlock: tx,
        sender: this.address,
      });

      const bytes = res.results![0]!.returnValues![0]![0];
      const parsed_balance = bcs.U64.parse(new Uint8Array(bytes));
      const balanceNumber = Number(parsed_balance);
      const adjusted_balance = balanceNumber / coin.scalar;

      return {
        coinType: coin.type,
        balance: Number(adjusted_balance.toFixed(9)),
      };
    } catch (err) {
      return {};
    }
  }

  async accountOpenOrders(turbosBalanceManager: string, poolKey: string): Promise<string[]> {
    const tx = new Transaction();
    const pool = this.deepBookConfig.getPool(poolKey);
    const baseCoin = this.deepBookConfig.getCoin(pool.baseCoin);
    const quoteCoin = this.deepBookConfig.getCoin(pool.quoteCoin);
    tx.moveCall({
      target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::turbos_balance_manager::account_open_orders`,
      arguments: [tx.object(turbosBalanceManager), tx.object(pool.address)],
      typeArguments: [baseCoin.type, quoteCoin.type],
    });

    try {
      const res = await this.sui.devInspectTransactionBlock({
        // @ts-ignore
        transactionBlock: tx,
        sender: this.address,
      });
      const order_ids = res.results![0]!.returnValues![0]![0];
      const VecSet = bcs.struct('VecSet', {
        constants: bcs.vector(bcs.U128),
      });
      return VecSet.parse(new Uint8Array(order_ids)).constants;
    } catch (err) {
      return [];
    }
  }

  async account(turbosBalanceManager: string, poolKey: string): Promise<LimitAccount | undefined> {
    const tx = new Transaction();
    const pool = this.deepBookConfig.getPool(poolKey);
    const baseCoin = this.deepBookConfig.getCoin(pool.baseCoin);
    const quoteCoin = this.deepBookConfig.getCoin(pool.quoteCoin);
    tx.moveCall({
      target: `${TURBOS_DEEPBOOK_V3_PACKAGE_ID}::turbos_balance_manager::account`,
      arguments: [tx.object(turbosBalanceManager), tx.object(pool.address)],
      typeArguments: [baseCoin.type, quoteCoin.type],
    });

    try {
      const res = await this.sui.devInspectTransactionBlock({
        // @ts-ignore
        transactionBlock: tx,
        sender: this.address,
      });

      if (res.error) {
        return;
      }

      const ID = bcs.struct('ID', {
        bytes: bcs.Address,
      });

      const Balances = bcs.struct('Balances', {
        base: bcs.u64(),
        quote: bcs.u64(),
        deep: bcs.u64(),
      });

      const VecSet = bcs.struct('VecSet', {
        constants: bcs.vector(bcs.U128),
      });

      const Account = bcs.struct('Account', {
        epoch: bcs.u64(),
        open_orders: VecSet,
        taker_volume: bcs.u128(),
        maker_volume: bcs.u128(),
        active_stake: bcs.u64(),
        inactive_stake: bcs.u64(),
        created_proposal: bcs.bool(),
        voted_proposal: bcs.option(ID),
        unclaimed_rebates: Balances,
        settled_balances: Balances,
        owed_balances: Balances,
      });

      const accountInformation = res.results![0]!.returnValues![0]![0];
      const accountInfo = Account.parse(new Uint8Array(accountInformation));

      return {
        epoch: accountInfo.epoch,
        open_orders: accountInfo.open_orders,
        taker_volume: Number(accountInfo.taker_volume) / baseCoin.scalar,
        maker_volume: Number(accountInfo.maker_volume) / baseCoin.scalar,
        active_stake: Number(accountInfo.active_stake) / DEEP_SCALAR,
        inactive_stake: Number(accountInfo.inactive_stake) / DEEP_SCALAR,
        created_proposal: accountInfo.created_proposal,
        voted_proposal: accountInfo.voted_proposal,
        unclaimed_rebates: {
          base: Number(accountInfo.unclaimed_rebates.base) / baseCoin.scalar,
          quote: Number(accountInfo.unclaimed_rebates.quote) / quoteCoin.scalar,
          deep: Number(accountInfo.unclaimed_rebates.deep) / DEEP_SCALAR,
        },
        settled_balances: {
          base: Number(accountInfo.settled_balances.base) / baseCoin.scalar,
          quote: Number(accountInfo.settled_balances.quote) / quoteCoin.scalar,
          deep: Number(accountInfo.settled_balances.deep) / DEEP_SCALAR,
        },
        owed_balances: {
          base: Number(accountInfo.owed_balances.base) / baseCoin.scalar,
          quote: Number(accountInfo.owed_balances.quote) / quoteCoin.scalar,
          deep: Number(accountInfo.owed_balances.deep) / DEEP_SCALAR,
        },
      };
    } catch (err) {
      return;
    }
  }

  calculatePrice(
    price: number,
    baseCoinScalar: number,
    quoteCoinScalar: number,
    pushBack: boolean = false,
  ) {
    if (pushBack) {
      return new Decimal(price)
        .div(FLOAT_SCALAR)
        .div(quoteCoinScalar)
        .mul(baseCoinScalar)
        .toNumber();
    }
    return Math.round((price * FLOAT_SCALAR * quoteCoinScalar) / baseCoinScalar);
  }

  // ---------------------------------------------------------------- //

  get pools() {
    return (this._pools ||= Object.entries(this.deepBookConfig.getPools()).map(([key, pool]) => {
      return { poolKey: key, ...pool };
    }));
  }

  get coins() {
    return (this._coins ||= Object.entries(this.deepBookConfig.getCoins()).map(([symbol, coin]) => {
      return {
        type: symbol === 'SUI' ? '0x2::sui::SUI' : coin.type,
        symbol: symbol,
        decimals: Number(String(coin.scalar).split('e').pop()),
      };
    }));
  }

  findPool(coinTypeA: string, coinTypeB: string) {
    const coinA = this.findCoinByType(coinTypeA);
    const coinB = this.findCoinByType(coinTypeB);
    if (!coinA || !coinB) return;
    return this.pools.find(
      (pool) => pool.baseCoin === coinA.symbol && pool.quoteCoin === coinB.symbol,
    );
  }

  findPoolByAddress(address: string) {
    return this.pools.find((pool) => pool.address === address);
  }

  findPoolByKey(poolKey: string) {
    return this.pools.find((pool) => pool.poolKey === poolKey);
  }

  findCoinByType(type: string) {
    return this.coins.find((coin) => coin.type === type);
  }

  findCoinBySymbol(symbol: string) {
    return this.coins.find((coin) => coin.symbol === symbol);
  }

  protected scaleIn(amount: number | string, symbol: string) {
    const coin = this.coins.find((coin) => coin.symbol === symbol)!;
    return new Decimal(amount).div(10 ** coin.decimals).toNumber();
  }

  protected zero(token: string) {
    return (tx: Transaction) => {
      return tx.moveCall({
        typeArguments: [token],
        target: `0x2::coin::zero`,
        arguments: [],
      });
    };
  }
}

export const deepbook = new DeepbookMarketMaker('0x0');
