import { parseGlobIcon } from '@utils/parse-glob-icon';

interface WalletItem {
  iconName: string;
  walletName: string;
  label: string;
  chromeExtension: string;
  hidden?: boolean;
}
export const walletList: WalletItem[] = [
  {
    iconName: 'sui',
    walletName: 'Sui Wallet',
    label: 'Sui Wallet',
    chromeExtension:
      'https://chrome.google.com/webstore/detail/sui-wallet/opcgpfmipidbgpenhmajoajpbobppdil',
  },
  {
    iconName: 'ethos',
    walletName: 'Ethos Wallet',
    label: 'Ethos',
    chromeExtension:
      'https://chrome.google.com/webstore/detail/ethos-sui-wallet/mcbigmjiafegjnnogedioegffbooigli',
  },
  {
    iconName: 'suiet',
    walletName: 'Suiet',
    label: 'Suiet',
    chromeExtension:
      'https://chrome.google.com/webstore/detail/suiet-sui-wallet/khpkpbbcccdmmclmpigdgddabeilkdpd',
  },
  {
    iconName: 'stashed',
    walletName: 'Stashed',
    label: 'Stashed',
    chromeExtension: 'https://getstashed.com/',
  },

  {
    iconName: 'metaMask',
    walletName: 'Sui MetaMask Snap',
    label: 'MetaMask',
    chromeExtension: 'https://snaps.metamask.io/snap/npm/kunalabs-io/sui-metamask-snap/',
  },
  {
    iconName: 'bybit',
    walletName: 'Bybit Wallet',
    label: 'Bybit Wallet',
    chromeExtension:
      'https://chromewebstore.google.com/detail/bybit-wallet/pdliaogehgdbhbnmkklieghmmjkpigpa',
  },
  {
    iconName: 'gate',
    walletName: 'Gate Wallet',
    label: 'Gate Wallet',
    chromeExtension:
      'https://chromewebstore.google.com/detail/gate-wallet/cpmkedoipcpimgecpmgpldfpohjplkpp',
  },
  {
    iconName: 'okx',
    walletName: 'OKX Wallet',
    label: 'OKX',
    chromeExtension:
      'https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge',
  },
  {
    iconName: 'binance',
    walletName: 'Binance Web3 Wallet',
    label: 'Binance',
    chromeExtension: '',
  },
  {
    iconName: 'martian',
    walletName: 'Martian Sui Wallet',
    label: 'Martian',
    chromeExtension:
      'https://chrome.google.com/webstore/detail/martian-wallet-for-sui-ap/efbglgofoippbgcjepnhiblaibcnclgk',
  },
  {
    iconName: 'safepal',
    walletName: 'SafePal',
    label: 'SafePal',
    chromeExtension:
      'https://chrome.google.com/webstore/detail/safepal-extension-wallet/lgmpcpglpngdoalbgeoldeajfclnhafa',
  },
  {
    iconName: 'coin98',
    walletName: 'Coin98',
    label: 'Coin98',
    chromeExtension:
      'https://chrome.google.com/webstore/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg',
  },
  {
    iconName: 'surf',
    walletName: 'Surf Wallet',
    label: 'Surf',
    chromeExtension:
      'https://chrome.google.com/webstore/detail/surf-wallet/emeeapjkbcbpbpgaagfchmcgglmebnen',
  },
  {
    iconName: 'nightly',
    walletName: 'Nightly',
    label: 'Nightly',
    chromeExtension:
      'https://chromewebstore.google.com/detail/nightly/fiikommddbeccaoicoejoniammnalkfa?pli=1',
  },
  {
    iconName: 'tokenPocket',
    walletName: 'TokenPocket Wallet',
    label: 'TokenPocket',
    chromeExtension: '',
  },

  {
    iconName: '',
    walletName: 'MSafe Wallet',
    label: 'MSafe',
    hidden: true,
    chromeExtension:
      'https://chromewebstore.google.com/detail/nightly/fiikommddbeccaoicoejoniammnalkfa?pli=1',
  },
];

export const zkLoginList: Omit<WalletItem, 'chromeExtension'>[] = [
  {
    iconName: 'google',
    walletName: 'Stashed',
    label: 'Google',
  },
  {
    iconName: 'twitch',
    walletName: 'Stashed',
    label: 'Twitch',
  },
];

export const getWalletIcon = parseGlobIcon(
  import.meta.glob('../assets/images/wallets/*', {
    eager: true,
    import: 'default',
  }),
);
